import App from 'next/app'
import router from 'next/router'
import { ToastProvider } from 'react-toast-notifications'
import AuthProvider from 'components/AuthProvider'

import 'styles/global.css'

import axios from 'axios'

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401 && error.response?.data?.message === 'session expired') {
      router.push('/login')
    }

    return Promise.reject(error)
  }
)

const MyApp = ({ Component, pageProps, err }) => {
  return (
    <AuthProvider>
      <ToastProvider>
        <Component {...pageProps} err={err} />
      </ToastProvider>
    </AuthProvider>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext)

  return { ...appProps }
}

export default MyApp
